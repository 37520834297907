<template>
		<div class="outside">
					<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item >角色权限</el-breadcrumb-item>
			  <el-breadcrumb-item>查看角色</el-breadcrumb-item>
			</el-breadcrumb>
    <el-form  style="margin-top: 20px;" :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
      <el-form-item label="角色名称" prop="roleName">
		  <div style="width: 300px;">{{dataForm.name}}</div>
      </el-form-item>
      <el-form-item label="备注" class="justify" prop="remark">
       <div style="width: 300px;">{{dataForm.des}}</div>
      </el-form-item>
      <el-form-item size="mini" style="line-height: 28px;" label="菜单权限">
      <el-tree :data="menuList" :props="menuListTreeProps" node-key="menuId" ref="menuListTree" :default-expand-all="true">
      </el-tree>
      </el-form-item>

    </el-form>
		</div>
</template>
<style>

</style>
<script>
	import Qs from 'qs'
  import { treeDataTranslate } from '@/utils'
  export default {
    data () {
      return {
        visible: false,
     menuList: [],
		 dataForm:'',
     menuListTreeProps: {
     	label: 'name',
     	children: 'children'
     },
        dataRule: {
        },

      }
    },
		created(){
			this.init();
		},
    methods: {

      init (id) {
				this.dataForm = JSON.parse(sessionStorage.getItem('roleInfo'))
				var Role = {
token:this.$cookie.get('token'),
				}
       this.$httpAes({
       					url: this.$httpAes.adornUrl('role/info?roleId='+this.dataForm.id),
       					headers: {
         'Content-Type': 'application/x-www-form-urlencoded',
			
			'token':this.$cookie.get('token'),
       },
		method: 'get',
       				}).then(({
       					data
       				}) => {
								this.menuList =  data.data.tree
	
       				})
      },

    }
  }
	function menuTreeDataTranslate (data) {
	   // 删除 所有 children,以防止多次调用
	        data.forEach(function (item) {
	            delete item.list;
	        });
	 
	        // 将数据存储为 以 id 为 KEY 的 map 索引数据列
	        var map = {};
	        data.forEach(function (item) {
	            map[item.code] = item;
	        });
	        var val = [];
	        data.forEach(function (item) {
	            // 以当前遍历项，的pid,去map对象中找到索引的id
	            var parent = map[item.pcode];
	            // 好绕啊，如果找到索引，那么说明此项不在顶级当中,那么需要把此项添加到，他对应的父级中
	            if (parent) {
	                (parent.list || ( parent.list = [] )).push(item);
	            } else {
	                //如果没有在map中找到对应的索引ID,那么直接把 当前的item添加到 val结果集中，作为顶级
	                val.push(item);
	            }
	        });
	        return val;
	
	}
</script>
